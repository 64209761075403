import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import  Link  from 'next/link';
import { toast } from 'react-toastify';
import { Form, Row, Col } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
//import Flex from 'components/common/Flex';
import { Formik, ErrorMessage } from 'formik';
import { setPersistence, createUserWithEmailAndPassword, updateProfile, sendEmailVerification, browserSessionPersistence, browserLocalPersistence} from "firebase/auth";
import * as Yup from 'yup';
//import AuthContext from "newComponents/AuthContext";

import { getAuth } from "firebase/auth";

 const RegistrationSchema = Yup.object().shape({
     name: Yup.string().min(2, "Too short").required("Required"),
     email: Yup.string().email("Invalid email").required("Required"),
     password: Yup.string().min(2, "Too short").required("Required"),
     confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
     acceptTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions to use the site")
 });

const RegistrationForm = ({ currentTab,setCurrentTab ,hasLabel//, layout 
                          }) => {
    //let authContext = useContext(AuthContext);

    return (
        <>
<Formik
      initialStatus="Submit"//What the button will say by default.
        initialValues={{ email: '', password: '',name:'',confirmPassword:'', acceptTerms:false,rememberMe:true }}
validationSchema={RegistrationSchema}
       
onSubmit= {(values, { //setErrors,
        setStatus,setSubmitting }) => {//onSubmit
console.log("STATE 1")
            console.log(setStatus);
    var pers = browserLocalPersistence;
if (values.rememberMe == false){
    console.log("browsersessionpersistence");
    pers = browserSessionPersistence;
}
        
        
        console.log("STATE 2")
setPersistence(getAuth(), pers)
  .then(() => {
    console.log("STATE 3")
    //return 
    createUserWithEmailAndPassword(getAuth(), values.email, values.password).then((userCredential) => {
        console.log("STATE 4")
    // Signed in 
        
        updateProfile(getAuth().currentUser, {
  displayName: values.name
}).then((whatever)=>{
            console.log("STATE 4.5");
            console.log(whatever);
                    toast.success(`Successfully registered as ${values.email}`, {
      theme: 'colored'
    });
            
            
sendEmailVerification(getAuth().currentUser)
  .then(() => {
    console.log("verification email sent");
    // Email verification sent!
    // ...
  });
        })
        console.log("STATE 5")
        
        //Should I update the token after updating profile? Will it do it automatically?
        
    console.log("just registered")
    console.log(userCredential);
    //const user = userCredential.user;

        console.log("STATE 6")
  })
  .catch((error) => {
        console.log("STATE 7")
    setSubmitting(false);
   // const errorCode = error.code;
    //const errorMessage = error.message;
             //  console.log("errorcode");
               console.log(error.code);
             //  alert(errorMessage);
               console.log("STATE 8")
let code = error.code.replace("auth/","").replaceAll("-"," ");
const words = code.split(" ");
for (let i = 0; i < words.length; i++) {
words[i] = words[i][0].toUpperCase() + words[i].substr(1);
}
code = words.join(" ");
        console.log("STATE 9")
setStatus(code);
        console.log("STATE 10")
});
                                                                      
       })}}//End on submit
       
     >{({//Dictionary being passed to an arrow function, to initiate the formik form and it's state. Keeps state separate from other formiks?
         values,
         errors,
         touched,
     //  isValid,
       status,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         // and other goodies 
       }) => (
         <Form noValidate onSubmit={handleSubmit}>

           <Row className="align-items-center mb-2">
           {/*<Col style={{"display":"none"}}>
        <h5 id="modalLabel">Register</h5>
      </Col>*/}
      <Col xs="auto" style={{"marginLeft":"auto"}}>
        <p className="fs--1 text-600 mb-0">
          Have an account? <a href="#/" onClick={()=>{setCurrentTab("LogIn") //authContext.changeAuthState({modalTab:"LogIn"})
        }}>Login</a>
        </p>
      </Col>
    </Row>
            
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Name' : ''}
          value={values.name}
          name="name"
          onChange={handleChange}
onBlur={handleBlur}
          type="text"
isInvalid={touched.name && errors.name}
        />
              <span className="invalid-feedback"><ErrorMessage name="name" /></span>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={values.email}
          name="email"
          onChange={handleChange}
onBlur={handleBlur}
isInvalid={touched.email && errors.email}
          type="text"
        />
              <span className="invalid-feedback"><ErrorMessage name="email" /></span>
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={values.password}
            name="password"
            onChange={handleChange}
onBlur={handleBlur}
isInvalid={touched.password && errors.password}
            type="password"
          />
                <span className="invalid-feedback"><ErrorMessage name="password" /></span>
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={values.confirmPassword}
            name="confirmPassword"
            onChange={handleChange}
onBlur={handleBlur}
isInvalid={touched.confirmPassword && errors.confirmPassword}
            type="password"
          />
                <span className="invalid-feedback"><ErrorMessage name="confirmPassword" /></span>
        </Form.Group>
      </Row>

                 <Row style={{marginBottom:"16px !important"}} className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="Somerandomidisneededapparentlyforthelabeltowork">
            <Form.Check.Input
              type="checkbox"
              name="rememberMe"
              checked={values.rememberMe}
           onChange={handleChange}
             onBlur={handleBlur}
            />
            <Form.Check.Label className="mb-0">Remember Me</Form.Check.Label>
          </Form.Check>
        </Col>
</Row>
<br/>
                {/*<Form.Group className="mb-3 nobotmargin" style={{marginBottom:"0px !important;"}}>*/}
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="acceptTerms"
            checked={values.acceptTerms}
            onChange={handleChange}
onBlur={handleBlur}
isInvalid={touched.acceptTerms && errors.acceptTerms}
          />
          <Form.Check.Label className="form-label">
            I accept the <Link href="#!">terms</Link> and{' '}
            <Link href="#!">privacy policy</Link>
          </Form.Check.Label>
<span className="invalid-feedback"><ErrorMessage name="acceptTerms" /></span>
        </Form.Check>
{/*</Form.Group>*/}
           
           <button style={{marginTop:"0px !important"}} type="submit" disabled={(()=>{
           if (isSubmitting){return true} 
           if (Object.keys(errors).length >= 1){return true} 
           if (Object.keys(touched).length == 0){return true} 
           return false; })() }
          
          className={ (()=>{if (status=='Submit'){return 'w-100 btn btn-primary'} return 'mt-3 w-100 btn btn-danger' })()}
           >{status}</button>
         </Form>

       )}
     </Formik>


      <Divider className="mt-4">or register with</Divider>

      <SocialAuthButtons />
            </>
    );
};

RegistrationForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

RegistrationForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default RegistrationForm;
