import Image from "next/image";
import React, {useContext} from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {AuthContext} from "~client/context/Auth";
//import GoogleSignInButton from "/images/btn_google_signin_dark_normal_web.png";



const SocialAuthButtons = () =>{
    

    const authContext = useContext(AuthContext);
    
    
    return (
  <Form.Group className="mb-0">
    <Row>
      <Col sm={4} style={{marginLeft:"auto",marginRight:"auto",width:250}} className="pe-sm-1">
        
        
        
       
                <Button 
onClick={()=>{
  console.log("google clicked"); 

//signInWithPopup(firebaseAuth, googleProvider);

  authContext.dispatchAuthData({type:"signInGoogle"});
 }}// .changeAuthState({signInGoogle:true});}}
          variant=""
          size="sm"
          className="mt-2 w-100"
        >

<Image src="/images/googlesignin.png" width="204" height="49" alt="Sign In With Google"     ></Image>
        </Button>
        
        </Col>
     {/*   <Button 
onClick={()=>{console.log("google clicked");  authContext.changeAuthState({signInGoogle:true});}}
          variant=""
          size="sm"
          className="btn-outline-google-plus mt-2 w-100"
        >
          <FontAwesomeIcon
            icon={['fab', 'google-plus-g']}
            transform="grow-8"
            className="me-2"
          />{' '}
          google
        </Button>
      </Col>
<Col sm={6} className="ps-sm-1">
        <Button
          variant=""
          size="sm"
          className="btn-outline-facebook mt-2 w-100"
        >
          <FontAwesomeIcon
            icon={['fab', 'facebook-square']}
            transform="grow-8"
            className="me-2"
          />{' '}
          facebook
        </Button>
      </Col>*/}
    </Row>
  </Form.Group>
);
}
export default SocialAuthButtons;
